// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const drawerConfigSlice = createSlice({
  name: "drawerConfigS",
  initialState: {
    cartOpen: false,
  },
  reducers: {
    setCartOpen: (state, action) => {
      state.cartOpen = action.payload;
    },
  },
});

export const { setCartOpen} = drawerConfigSlice.actions;

export default drawerConfigSlice.reducer;
