/* eslint eqeqeq: 0 */
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { t } from "i18next";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import {
  updateBaseCart,
  updateSelectedView,
} from "../../../redux/cart";
import StarIcon from "@mui/icons-material/Star";
import { setPromoCode } from "../../../redux/Promocode";
import { DECIMAL_POINT } from "../../../config/config";
import api from "../../../API/apiCollection";
import { useTheme } from "@emotion/react";
import { cartDetails } from "../../../redux/orderCartDetails";
import { setDeliveryAddress } from "../../../redux/DeliveryAddress";
import noProviderImage from "../../../Images/no-provider.png";
import { formatMoney, placeholderImage } from "../../../util/Helper";

// Single Cart Item
const CartItem = ({ item, onDelete }) => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const theme = useTheme();

  function capitalizeFirstLetter(inputString) {
    if (typeof inputString !== "string" || inputString?.length === 0) {
      return inputString;
    }
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  // Clear promo code
  const removePromo = () => {
    dispatch(setPromoCode([]));
  };

  // Nút xoá sản phẩm
  const handleDeleteClick = () => {
    removePromo();
    onDelete(item);
  };


  return (
    <Card
      sx={{
        display: "flex",
        gap: 2,
        p: 2,
        bgcolor: "transparent",
        boxShadow: "none",
        flexWrap: { xs: "wrap", md: "inherit" },
        alignItems: "flex-start",
      }}
    >
      {/* Hình to hơn */}
      <img
        src={item.servic_details?.image_of_the_service}
        alt={item.servic_details?.title}
        onError={placeholderImage}
        style={{
          width: 140,
          height: 140,
          objectFit: "cover",
          borderRadius: 8,
          flexShrink: 0,
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <CardContent sx={{ flex: "1 0 auto", pt: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
            }}
          >
            <Typography
              component="div"
              color={theme?.palette?.primary?.main}
              fontWeight={"bold"}
              variant="subtitle1"
            >
              {item.servic_details?.title}
            </Typography>


            {/* Hiển thị rating (nếu muốn) */}
            <Box display={"flex"} alignItems={"center"}>
              <StarIcon sx={{ color: "gold", fontSize: 16, mr: 0.5 }} />
              <Typography component="div" variant="subtitle2">
                {parseFloat(item.servic_details?.rating).toFixed(1)}
              </Typography>
            </Box>
          </Box>

          {/* Thông tin thêm (nếu cần) */}
          <Typography variant="subtitle2" color="text.secondary" component="div" mt={0.5}>
            {item.servic_details?.duration} {t("min")}
          </Typography>
        </CardContent>

        {/* Thay thế cụm tăng/giảm qty bằng nút xoá */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, pl: 1, pb: 2 }}>
          {/* Cập nhật nút xoá để trông hiện đại hơn */}
          <IconButton

            onClick={handleDeleteClick}
            color="error"
            sx={{
              border: `1px solid ${theme.palette.error.main}`,
              borderRadius: "8px",
              padding: "6px",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: theme.palette.error.light,
              },
            }}
          >
            <DeleteIcon />
            <Typography variant="button" sx={{ ml: 0.5 }}>
              {t("Xoá khỏi giỏ hàng")}
            </Typography>
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

// Danh sách toàn bộ Cart
export const Cart = ({ continueClicked }) => {
  const decimal_point = DECIMAL_POINT();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const theme = useTheme();

  // Mỗi lần render Cart, reset một số state
  dispatch(updateSelectedView(""));
  dispatch(setDeliveryAddress(""));
  dispatch(cartDetails({ orderNote: "" }));

  // Hàm xoá item
  const handleDelete = async (item) => {
    try {
      const result = await api.removeCart({ itemId: item.service_id });
      if (result) {
        dispatch(updateBaseCart(result));
        toast.success(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const settings = useSelector((state) => state.Settings)?.settings;
  const currency_symbol = settings?.app_settings?.currency;

  return (
    <div>
      <Box padding={1}>
        <Divider />

        {/* Kiểm tra giỏ hàng rỗng */}
        {cart?.base_cart?.length === 0 || cart?.base_cart?.data?.length === 0 ? (
          <Box textAlign={"center"} maxWidth={"100%"}>
            <Box
              component={"img"}
              src={noProviderImage}
              alt="Nothing in cart"
              sx={{ width: "100%", borderRadius: "500px" }}
            />
            <h3>{t("no_products")}</h3>
          </Box>
        ) : (
          <>
            {/* Liệt kê các item trong giỏ */}
            {cart?.base_cart?.data?.map((item) => (
              <Box key={item.id}>
                <Box my={2}>
                  <CartItem
                    item={item}
                    onDelete={handleDelete}
                  />
                </Box>
                <Divider sx={{ marginTop: 2 }} />
              </Box>
            ))}
          </>
        )}

        {/* Nút "Continue" nếu giỏ hàng không rỗng */}
        {cart?.base_cart == null ||
        cart?.base_cart?.length === 0 ||
        cart?.base_cart?.data?.length === 0 ? (
          ""
        ) : (
          <Box
            mt={2}
            mb={2}
            borderRadius={"var(--global-border-radius)"}
            sx={{ backgroundColor: theme.palette.background.buttonColor }}
          >
            {/* Cập nhật nút "Continue" */}
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: theme.palette.primary.main, // Thay đổi màu nền
                color: "#fff", // Màu chữ trắng
                padding: "12px 0", // Đệm lớn hơn
                borderRadius: "var(--global-border-radius)",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "bold",
                boxShadow: theme.shadows[2],
                transition: "background-color 0.3s, transform 0.3s",
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark, // Màu nền khi hover
                  transform: "scale(1.02)", // Hiệu ứng phóng to nhẹ khi hover
                  boxShadow: theme.shadows[4],
                },
              }}
              onClick={() => continueClicked()}
            >
              {t("Đi đến chọn lịch hẹn")}
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Cart;
