// Import các thư viện cần thiết
import {
  Box,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { t } from "i18next";
import api from "../API/apiCollection";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../redux/cart";
import { orderCartDetailsReset } from "../redux/orderCartDetails";
import { deliveryAddressReset } from "../redux/DeliveryAddress";
import { useNavigate } from "react-router";
import { resetStatePromo } from "../redux/Promocode";
import { setPaymentMode } from "../redux/Settings";
import PaymentIcon from '@mui/icons-material/Payment'; // Import Payment icon từ MUI

const MoMoPayment = ({ amount }) => {
  const settings = useSelector(
    (state) => state?.Settings?.settings?.payment_gateways_settings
  );
  const orderDetails = useSelector(
    (state) => state.OrderCartDetails?.orderDetails
  );
  const cartData = useSelector(
    (state) => state.cart?.base_cart?.data
  );
  const testtt = useSelector(
    (state) => state
  );
  const delivery_type = useSelector(
    (state) => state.DeliveryAddress?.deliveryType
  );

  const deliveryAddress = useSelector(
    (state) => state.DeliveryAddress?.delivery
  );

  const date = orderDetails?.date;
  const time = orderDetails?.slot;

  const address_id = deliveryAddress;
  const address = address_id !== "" ? deliveryAddress : "";

  const [order_id, setOrderID] = useState(0);

  const navigate = useNavigate();

  let promocode = useSelector((state) => state.Promocode);
  let selectedPromoCode = promocode.selectedPromoCode;

  const dispatch = useDispatch();

  const orderNotes = orderDetails.orderNote;

  const handleMoMoPayment = async () => {
    try {
      // Gọi API placeOrder với phương thức thanh toán là "momo"
      const result = await api.placeOrder({
        method: "momo",
        date: date,
        time: time,
        addressId: delivery_type === "Home" ? address?.id : "",
        order_note: orderNotes,
        promo_code_id: selectedPromoCode ? selectedPromoCode.id : ""
      });

      if (result.error === false || result.error === "false") {
        setOrderID(result.data.order_id);

        if (result.data.momo_link) {
          // Chuyển hướng người dùng đến momo_link
          window.location.href = result.data.momo_link;
        } else {
          toast.error(t("Có lỗi xảy ra khi tạo liên kết thanh toán MoMo"));
        }
      } else {
        if (typeof result.message === "object") {
          Object.values(result.message).forEach((e) => {
            toast.error(e);
          });
        } else {
          toast.error(result.message);
        }
      }
    } catch (error) {
      console.error("Error creating MoMo payment:", error);
      toast.error("Không thể bắt đầu thanh toán bằng MoMo. Vui lòng thử lại sau.");
    }
  };

  return (
    <Box>
      <Button
        fullWidth
        sx={{
          my: 1,
          textTransform: 'none',
          fontWeight: 'bold',
          borderRadius: 2,
          padding: '12px 16px',
          background: 'linear-gradient(45deg, #FF512F, #DD2476)', // Gradient từ cam đến hồng
          color: '#fff',
          '&:hover': {
            background: 'linear-gradient(45deg, #DD2476, #FF512F)', // Đảo ngược gradient khi hover
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: 'none', // Bỏ đổ bóng
        }}
        variant="contained"
        startIcon={<PaymentIcon />}
        onClick={() => {
          dispatch(setPaymentMode("momo"));
          handleMoMoPayment();
        }}
      >
        {t("Thanh toán tại đây")}
      </Button>
    </Box>
  );
};

export default MoMoPayment;