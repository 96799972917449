// Import các thư viện cần thiết
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from "@mui/material";
import {
  ArrowBackIosNewOutlined,
  ConfirmationNumber,
  Place,
  AccessTime,
  Delete,
  ExpandMore,
  InfoOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// Import MoMoPayment
import MoMoPayment from "../PaymentGateways/MoMoPayment"; // Thêm MoMoPayment
// Import Redux actions
import {
  selectedPromoCodeData,
  setPromoCode,
  setSelectedPromocode,
} from "../redux/Promocode";
import { cartDetails, orderCartDetailsReset } from "../redux/orderCartDetails";
import { resetState } from "../redux/cart";
import { paymentMode, setPaymentMode } from "../redux/Settings";
import api from "../API/apiCollection";
import { formatMoney } from "../util/Helper"; // Đảm bảo rằng bạn đã tạo hàm này

// Styled components
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const BookingInfoDrawerNew = ({ setForm, setPromo, setBooking }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State và biến
  const [rows, setRows] = useState([]);
  const [amount, setAmount] = useState(0);
  const [visitingCharges, setVisitingCharges] = useState(0);
  const [addressInfo, setAddressInfo] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);

  // Sử dụng Redux selectors
  const settings = useSelector((state) => state.Settings)?.settings;
  const currency_symbol = settings?.app_settings?.currency || "₫"; // Thêm fallback nếu không có
  const delivery_type = useSelector(
    (state) => state.DeliveryAddress
  )?.deliveryType;
  const orderDetails = useSelector(
    (state) => state.OrderCartDetails
  )?.orderDetails;
  const deliveryAddress = useSelector(
    (state) => state.DeliveryAddress
  )?.delivery;
  const selectedPromo = useSelector(selectedPromoCodeData);
  const paymentModeData = useSelector(paymentMode);
  const AllCartData = useSelector((state) => state.cart);
  const promocode = useSelector((state) => state.Promocode);
  const coupanCodes = promocode.promocode;
  const selectedPromoCode = promocode.selectedPromoCode;

  const decimal_point = 2; // Điều chỉnh nếu cần

  useEffect(() => {
    // Thiết lập amount và rows dựa trên dữ liệu giỏ hàng
    let cartData =
      AllCartData?.isFrom === "reorder"
        ? AllCartData?.reOrder
        : AllCartData?.base_cart;
    if (cartData) {
      let totalAmount = Number(cartData.sub_total);
      if (delivery_type === "Home") {
        totalAmount += Number(cartData.visiting_charges);
        setVisitingCharges(Number(cartData.visiting_charges));
      }
      setAmount(totalAmount);
      setRows(cartData.data);
    }
  }, [AllCartData, delivery_type]);

  useEffect(() => {
    if (deliveryAddress) {
      setAddressInfo(deliveryAddress);
    }
  }, [deliveryAddress]);

  // Xử lý nút quay lại
  const back = () => {
    setBooking(false);
    setForm(true);
  };

  // Mở drawer mã giảm giá
  const openPromo = () => {
    setBooking(false);
    setPromo(true);
  };

  // Xóa mã giảm giá
  const removePromo = () => {
    dispatch(setPromoCode([]));
    if (selectedPromo) {
      dispatch(setSelectedPromocode(null));
      toast.success(t("Đã xóa mã giảm giá"));
    } else {
      toast.error(t("Không có mã giảm giá nào được chọn"));
    }
  };

  // Thiết lập phương thức thanh toán (Chỉ giữ lại MoMo và tự động chọn)
  useEffect(() => {
    const methods = [
      {
        title: "Thanh toán qua QR đa năng",
        paymentType: "momo",
        enabled: true,
      }, // Chỉ giữ lại MoMo với tiêu đề mới
    ];
    setPaymentMethods(methods);
    setActivePaymentMethod("momo"); // Tự động chọn MoMo
  }, []);

  // Đặt hàng
  const placeOrder = async () => {
    let address = "";
    if (delivery_type === "Home") {
      address = deliveryAddress;
    }

    try {
      const result = await api.placeOrder({
        method: paymentModeData,
        date: orderDetails?.date,
        time: orderDetails?.slot,
        addressId: delivery_type === "Home" ? address?.id : "",
        order_note: orderDetails?.orderNote,
        promo_code_id: selectedPromoCode?.id,
      });

      if (paymentModeData === "cod") {
        toast.success(t("Đặt dịch vụ thành công"));
      } else {
        toast.success(result.message);
      }

      if (!result.error) {
        setTimeout(async () => {
          await api.add_transactions({
            orderID: result.data.order_id,
            status: "success",
          });

          dispatch(orderCartDetailsReset());
          dispatch(resetState());
          navigate("/profile/booking/services/" + result.data.order_id);
          dispatch(cartDetails({ orderNote: "" }));
        }, 2000);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(t("Đặt hàng thất bại, vui lòng thử lại."));
    }
  };

  // Render component thanh toán dựa trên phương thức được chọn (Chỉ xử lý MoMo)
  const renderPaymentComponent = (amountToPay) => {
    const paymentAmount =
      coupanCodes.length > 0
        ? amountToPay - coupanCodes[0]?.final_discount
        : amountToPay;
    if (activePaymentMethod === "momo") {
      return <MoMoPayment amount={paymentAmount} />;
    }
    return null;
  };

  // 1. Cấu hình tỷ lệ đặt cọc
  const DEPOSIT_PERCENTAGE = 30; // Có thể thay đổi thành 100 để thanh toán toàn bộ

  // 2. Tính toán tổng cộng, số tiền đặt cọc và số tiền còn lại

  console.log("AllCartData", AllCartData)
  const subtotal =
    AllCartData?.isFrom === "reorder"
      ? AllCartData?.reOrder?.sub_total
      : AllCartData?.base_cart?.sub_total;
  const totalAmount =
    subtotal +
    (delivery_type === "Home" ? visitingCharges : 0) -
    (coupanCodes.length > 0 ? coupanCodes[0]?.final_discount : 0);
  const depositAmount = (totalAmount * DEPOSIT_PERCENTAGE) / 100;
  const remainingAmount = totalAmount - depositAmount;

  return (
    <Box
      sx={{
        width: "100%",
        p: 3,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* Header với tiêu đề và nút quay lại */}
      <Box mb={3} display="flex" alignItems="center">
        <IconButton onClick={back} size="small">
          <ArrowBackIosNewOutlined
            sx={{ color: theme.palette.text.primary }}
            fontSize="small"
          />
        </IconButton>
        <Typography variant="h5" fontWeight={600} ml={1}>
          {t("Thanh toán dịch vụ trả trước")}
        </Typography>
      </Box>
      <Divider sx={{ mb: 3 }} />

      <Box sx={{ px: 2 }}>
        {/* Thông tin đặt dịch vụ */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Box display="flex" alignItems="center">
                  <AccessTime
                    sx={{ mr: 2, color: theme.palette.primary.main }}
                  />
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      {t("Ngày đặt")}
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      {dayjs(orderDetails?.date).format("DD/MM/YYYY")},{" "}
                      {orderDetails?.slot}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {delivery_type === "Home" && (
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <Place sx={{ mr: 2, color: theme.palette.primary.main }} />
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t("Địa chỉ của bạn")}
                      </Typography>
                      <Typography variant="h6" fontWeight="bold">
                        {addressInfo?.address}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={openPromo}
              sx={{
                textTransform: "none",
                borderRadius: "12px",
                background: "linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)",
                color: "white",
                fontWeight: "bold",
                fontSize: "1rem",
                py: 1.5,
                boxShadow: "0 4px 14px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease",
                "&:hover": {
                  background:
                    "linear-gradient(135deg, #5A67D8 0%, #0A0FDD 100%)",
                  boxShadow: "0 6px 20px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              {t("Áp dụng mã giảm giá")}
            </Button>
          </Grid>

          {coupanCodes.length > 0 && (
            <Grid item xs={12}>
              <Card
                variant="outlined"
                sx={{
                  borderRadius: "16px",
                  boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                  background: theme.palette.background.paper,
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: "0 12px 32px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <ConfirmationNumber
                      sx={{
                        mr: 2,
                        fontSize: "2rem",
                        color: theme.palette.primary.main,
                        transition: "color 0.3s ease",
                        "&:hover": {
                          color: theme.palette.secondary.main,
                        },
                      }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          color: theme.palette.text.primary,
                        }}
                      >
                        {selectedPromoCode?.promo_code}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            "linear-gradient(135deg, #667EEA 0%, #764BA2 100%)",
                          fontWeight: "medium",
                        }}
                      >
                        {selectedPromoCode?.discount}
                        {selectedPromoCode?.discount_type === "percentage"
                          ? "%"
                          : currency_symbol}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={removePromo}
                      sx={{
                        color: theme.palette.error.main,
                        transition: "transform 0.2s ease",
                        "&:hover": {
                          transform: "scale(1.1)",
                          color: theme.palette.error.dark,
                        },
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        {/* Bảng chi tiết giỏ hàng */}
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableBody>
              {rows?.length > 1 &&
                rows.map((row) => (
                  <StyledTableRow key={row.id}>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {row.servic_details.title}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle1">{row.qty}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle1">
                        {formatMoney(
                          row.servic_details.price_with_tax,
                          currency_symbol
                        )}
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                ))}
              <StyledTableRow>
                <TableCell>
                  <Typography variant="subtitle2" color="text.secondary">
                    {t("Tạm tính")}
                  </Typography>
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <Typography variant="subtitle1">
                    {formatMoney(subtotal, currency_symbol)}
                  </Typography>
                </TableCell>
              </StyledTableRow>
              {delivery_type === "Home" && (
                <StyledTableRow>
                  <TableCell>
                    <Typography variant="subtitle2" color="text.secondary">
                      {t("Phí dịch vụ")}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2} align="right">
                    <Typography variant="subtitle1">
                      +{formatMoney(visitingCharges, currency_symbol)}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              )}
              {coupanCodes.length > 0 && (
                <StyledTableRow>
                  <TableCell>
                    <Typography variant="subtitle2" color="text.secondary">
                      {t("Giảm giá")}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2} align="right">
                    <Typography variant="subtitle1">
                      -
                      {formatMoney(
                        coupanCodes[0]?.final_discount,
                        currency_symbol
                      )}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              )}
              {/* Hiển thị Đặt cọc (30%) rõ ràng */}
              <StyledTableRow>
                <TableCell>
                  <Typography variant="h6" fontWeight="bold">
                    {t(`Đặt cọc (${DEPOSIT_PERCENTAGE}%)`)}
                  </Typography>
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <Typography variant="h6" fontWeight="bold" color="primary">
                    {formatMoney(depositAmount, currency_symbol)}
                  </Typography>
                </TableCell>
              </StyledTableRow>
              {/* Hiển thị Tổng cộng và Số tiền còn lại trong Accordion */}
              <StyledTableRow>
                <TableCell colSpan={3} sx={{ padding: 0 }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6" fontWeight="bold">
                        {t("Chi tiết thanh toán")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" justifyContent="space-between" mb={1}>
                        <Typography variant="body1">
                          {t("Tổng cộng")}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="primary"
                        >
                          {formatMoney(totalAmount, currency_symbol)}
                        </Typography>
                      </Box>
                      {/* <Box display="flex" justifyContent="space-between">
                        <Typography variant="body1">{t('Số tiền còn lại')}</Typography>
                        <Typography variant="body1" fontWeight="bold" color="primary">
                          {formatMoney(remainingAmount, currency_symbol)}
                        </Typography>
                      </Box> */}
                    </AccordionDetails>
                  </Accordion>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Thông tin đặt cọc */}
        <Card variant="outlined" sx={{ mt: 2 }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <InfoOutlined sx={{ mr: 1, color: theme.palette.warning.main }} />
              <Typography variant="body2" color="text.secondary">
                {t(
                  `Bạn chỉ cần đặt cọc ${DEPOSIT_PERCENTAGE}% để hoàn tất đơn hàng. Số tiền còn lại sẽ được thanh toán khi nhận dịch vụ.`
                )}
              </Typography>
            </Box>
          </CardContent>
        </Card>

        {/* Phương thức thanh toán */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            {t("Thanh toán qua QR đa năng")}
          </Typography>

          <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
            {t("Tất cả các ngân hàng và ví điện tử Việt Nam")}
          </Typography>
          {/* Hiển thị component thanh toán MoMo */}
          {renderPaymentComponent(depositAmount)}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingInfoDrawerNew;
