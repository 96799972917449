import { useTheme } from "@emotion/react";
import { ArrowRightAltOutlined, Done } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Divider,
  Rating,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom"; // Thêm useNavigate
import slugify from "slugify";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import toast from "react-hot-toast";
import { t } from "i18next";
import api from "../../../API/apiCollection";
import { useSelector, useDispatch } from "react-redux";
import { setBookmark } from "../../../redux/Bookmark";
import { placeholderImage } from "../../../util/Helper";
import { updateBaseCart } from "../../../redux/cart";
import { setPromoCode } from "../../../redux/Promocode";
import { handleOpen } from "../../../config/config";
import { setCartOpen } from "../../../redux/DrawerConfig";

// Hàm format tiền
const formatMoney = (amount, currencySymbol = "₫") => {
  return amount ? `${currencySymbol} ${Number(amount).toLocaleString()}` : `${currencySymbol} 0`;
};

const Partner = ({
  partner,
  famousPlace = null,
  category = null,
  isHome = false,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Khai báo useNavigate

  // Dữ liệu từ redux
  const authentication = useSelector((state) => state.authentication)?.isLoggedIn;
  const locationData = useSelector((state) => state.Location);
  const lat = locationData.lat;
  const lng = locationData.lng;
  const marked = useSelector((state) => state.Bookmark);
  const categories = useSelector((state) => state.Pages.category);
  const famousPlaces = useSelector((state) => state.HinhcoLocation?.famousPlaceData?.famous_places) || [];

  // Dữ liệu của partner
  const slug = slugify(partner.company_name, { lower: true });
  const partnerId = partner.partner_id || partner.id;
  const price = categories?.find((cat) => cat.id === category)?.price || partner.price;

  const isBookmarked = marked?.bookmark?.some((bookmark) => bookmark.partner_id === partner.partner_id);

  // Hàm xử lý bookmark
  const handleBookmark = async (item) => {
    const bookmarks = marked?.bookmark ?? [];
    const isBookmarked1 = bookmarks.some((bookmark) => bookmark.partner_id === item.partner_id);

    if (isBookmarked1) {
      dispatch(setBookmark(bookmarks.filter((bookmark) => bookmark.partner_id !== item.partner_id)));
    } else {
      const newMark = { partner_id: item.partner_id };
      dispatch(setBookmark([...bookmarks, newMark]));
    }

    // Gọi API nếu đã đăng nhập
    if (authentication) {
      try {
        const response = await api.bookmark({
          type: isBookmarked1 ? "remove" : "add",
          lat,
          lng,
          partner_id: item.partner_id,
        });

        if (response && response.data) {
          toast.success(response.message);
        } else {
          dispatch(setBookmark(bookmarks.filter((bookmark) => bookmark.partner_id !== item.partner_id)));
        }
      } catch (error) {
        console.error("Error handling bookmark:", error);
      }
    }
  };

// Hàm xử lý "Đặt lịch ngay"
const handleBookNow = async (event) => {
  event.preventDefault();
  if (!authentication) {
    toast.error("Bạn cần đăng nhập để đặt lịch.");
    return;
  }

  try {
    const response = await api.allServices({
      partner_id: partnerId,
      company_name: partner.company_name,
      latitude: lat,
      longitude: lng,
      offset: "0",
      limit: "10",
    });

    if (response?.data?.length) {
      const firstService = response.data[0];
      dispatch(setPromoCode([]));

      const addServiceResponse = await api.ManageCart({
        id: firstService.id,
        qty: "1",
      });

      if (addServiceResponse?.data) {
        dispatch(updateBaseCart(addServiceResponse));
        toast.success(addServiceResponse.message || "Đã thêm dịch vụ vào giỏ.");

        // Chuyển hướng tới trang /providers/services/${partnerId}/${slug}
        navigate(`/providers/services/${partnerId}/${slug}`); // Chuyển hướng tới trang dịch vụ của đối tác
        dispatch(setCartOpen(true));
      }
    } else {
      toast.error("Không có dịch vụ nào khả dụng cho đối tác này.");
    }
  } catch (error) {
    console.error("Lỗi khi gọi API allServices / ManageCart:", error);
    toast.error("Đã xảy ra lỗi, vui lòng thử lại sau.");
  }
};


  // Tìm category và famous place tương ứng
  const renderCategory = category && categories.find((cat) => cat.id === category);
  const renderFamousPlace = famousPlaces.find((place) => place.id === famousPlace);

  return (
    <Card key={partner.id} mb={{ xs: "10px", md: 5 }} sx={{ my: { xs: "10px", md: 0 }, borderRadius: "var(--global-border-radius)", position: "relative" }}>
      <Box display={"flex"} className="serviceBookMarkBox">
        {isHome === false && authentication && (
          <Checkbox
            key={partner.id}
            size="small"
            checked={isBookmarked}
            sx={{ color: "white" }}
            icon={<BookmarkBorderIcon sx={{ color: theme?.palette?.primary?.main }} />}
            checkedIcon={<BookmarkIcon sx={{ color: theme?.palette?.primary?.main }} />}
            onClick={(event) => {
              event.stopPropagation();
              handleBookmark(partner);
            }}
          />
        )}
      </Box>

      <Link to={`/providers/services/${partnerId}/${slug}`} className="breadcrumb text-light">
        <img alt="partner" src={partner.banner_image} onError={placeholderImage} className="partner_image" />
        <Box className="partner-cardmedia" p={"5px"} sx={{ bgcolor: "white", borderColor: theme?.palette?.primary?.main }}>
          <CardMedia image={partner.image} alt={partner.company_name} sx={{ width: "100%", height: "100%", borderRadius: "50%" }} />
        </Box>

        <Box textAlign={"center"} sx={{ margin: "auto", width: "100%" }}>
          <CardContent sx={{ pb: "0!important", px: "0!important" }}>
            <Typography variant="h5" component="div" textAlign={"center"} color={theme.palette.color.navLink}>
              {partner.company_name}
            </Typography>

            {/* Famous Place & Category */}
            <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 2, justifyContent: "center" }}>
              {renderFamousPlace && (
                <Box sx={{ display: "flex", alignItems: "center", border: `1px solid ${theme.palette.primary.main}`, borderRadius: "8px", px: 2, py: 1 }}>
                  <Typography variant="body2" color={theme.palette.text.primary}>
                    {`Chúng ta sẽ chụp tại ${renderFamousPlace.name}`}
                  </Typography>
                </Box>
              )}

              {renderCategory && (
                <Box sx={{ display: "flex", alignItems: "center", border: `1px solid ${theme.palette.primary.main}`, borderRadius: "8px", px: 2, py: 1 }}>
                  <CardMedia image={renderCategory.category_image} alt={renderCategory.name} component="img" sx={{ width: 40, height: 40, borderRadius: "8px", marginRight: 1 }} />
                  <Typography variant="body2" color={theme.palette.text.primary}>
                    {renderCategory.name}
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Giá tiền */}
            {price > 0 && (
              <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                <Typography variant="h6" color={theme.palette.primary.main}>
                  {t("Giá:")} {formatMoney(price)}
                </Typography>
              </Box>
            )}

            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={1} mt={2}>
              <Box display={"flex"} alignItems={"center"} gap={1}>
              <Rating name="read-only" value={parseInt(partner.ratings ?? partner.average_rating)} readOnly className="aling-content" />
                <Typography variant="body1" color={theme.palette.color.textColor}>
                  ({partner.ratings ? parseFloat(partner.ratings).toFixed(1) : "0"} / 5)
                </Typography>
              </Box>

              <Button size="small" sx={{ borderRadius: "8px", textTransform: "none" }} variant="contained" style={{ backgroundColor: "#272930" }} startIcon={<Done sx={{ backgroundColor: "white", borderRadius: "50%", color: theme?.palette?.primary?.main }} />}>
                <Box display={"flex"} gap={0.5}>
                  <Box>{partner.number_of_orders}</Box>
                  <Box>{t("order_completed")}</Box>
                </Box>
              </Button>
            </Box>

            <Divider sx={{ mt: 2 }} />

            {/* Additional buttons */}
            <Box display="flex" flexWrap="wrap" padding={3} justifyContent="center" gap={2} mt={2}>
              {/* Book Now button */}
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "8px",
                  textTransform: "none",
                  minWidth: "150px",
                  flex: "1 1 auto",
                }}
                startIcon={<Done />}
                onClick={handleBookNow} // Gọi hàm thay vì chuyển trang
              >
                {t("Đặt lịch ngay")}
              </Button>

              {/* View All Services button */}
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  borderRadius: "8px",
                  textTransform: "none",
                  minWidth: "150px",
                  flex: "1 1 auto",
                }}
                endIcon={<ArrowRightAltOutlined />}
                onClick={() => navigate(`/view_all_services/${partnerId}`)} // Chuyển trang khi nhấn vào button
              >
                {t("view_all_services")}
              </Button>
            </Box>
          </CardContent>
        </Box>
      </Link>
    </Card>
  );
};

export default Partner;

