// AddressDrawer.jsx

// Import Statements
import React, { useState, useEffect, useMemo, useCallback, memo } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
  styled,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Grow,
  InputAdornment,
} from "@mui/material";
import {
  ArrowBackIosNewOutlined,
  CheckCircle,
  Close,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { handleClose, handleOpen } from "../config/config";
import { t } from "i18next";
import api from "../API/apiCollection";
import toast from "react-hot-toast";

// Redux Actions
import { cartDetails } from "../redux/orderCartDetails";
import { setDeliveryAddressType } from "../redux/DeliveryAddress";
import { updateSelectedView } from "../redux/cart";

// Custom Components
import DateTimeSelector from "./DateTimeSelector";

// Styled Components

// Header Title
const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem", // 24px
  fontWeight: 700,
  color: theme.palette.text.primary,
  letterSpacing: "0.5px",
}));

// Section Title
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem", // 20px
  fontWeight: 600,
  marginBottom: "16px",
  color: theme.palette.text.primary,
  letterSpacing: "0.25px",
}));

// Subsection Title
const SubSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1rem", // 16px
  fontWeight: 500,
  marginBottom: "8px",
  color: theme.palette.text.secondary,
  letterSpacing: "0.15px",
}));

// Primary Button
const PrimaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#FFFFFF",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    opacity: 0.9,
  },
  padding: "12px 0",
  marginTop: "24px",
}));

// Styled Typography for Truncated Text
const TruncatedTitle = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: "1rem", // Reduced font size for better fit
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

const TruncatedDescription = styled(Typography)(({ theme }) => ({
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "0.875rem", // 14px
  color: theme.palette.text.secondary,
}));

// ProvinceCard Component
const ProvinceCard = memo(({ province, isSelected, onSelect }) => {
  const theme = useTheme();

  return (
    <Card
      onClick={() => onSelect(province)}
      sx={{
        cursor: "pointer",
        border: isSelected
          ? `2px solid ${theme.palette.primary.main}`
          : "1px solid #e0e0e0",
        boxShadow: isSelected
          ? `0 4px 20px rgba(2, 119, 250, 0.2)`
          : "none",
        transition: "border 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          boxShadow: `0 4px 20px rgba(0, 0, 0, 0.1)`,
        },
        height: "60px",
        minWidth: "150px",
        maxWidth: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      elevation={isSelected ? 8 : 2}
    >
      <CardContent
        sx={{
          padding: "8px",
          paddingBottom: "8px !important",
        }}
      >
        <TruncatedTitle
          variant="h6"
          align="center"
          title={province.name_with_type}
        >
          {province.name_with_type}
        </TruncatedTitle>
      </CardContent>
    </Card>
  );
});

// FamousPlaceCard Component
const FamousPlaceCard = memo(({ place, isSelected, onSelect }) => {
  const theme = useTheme();

  return (
    <Grow in timeout={300}>
      <Card
        onClick={() => onSelect(place)}
        sx={{
          cursor: "pointer",
          position: "relative",
          border: isSelected
            ? `2px solid ${theme.palette.primary.main}`
            : "1px solid #e0e0e0",
          boxShadow: isSelected
            ? `0 4px 20px rgba(2, 119, 250, 0.2)`
            : "none",
          transition: "border 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            boxShadow: `0 4px 20px rgba(0, 0, 0, 0.1)`,
          },
          display: "flex",
          flexDirection: "column",
          height: "200px",
          maxWidth: "100%",
        }}
        elevation={isSelected ? 8 : 2}
      >
        <CardMedia
          component="img"
          height="120"
          image={JSON.parse(place.images)[0]} // Display first image
          alt={place.name}
          sx={{ objectFit: "cover" }}
        />
        <CardContent sx={{ flexGrow: 1, padding: "8px" }}>
          <TruncatedTitle variant="h6" title={place.name}>
            {place.name}
          </TruncatedTitle>
          <TruncatedDescription variant="body2">
            {place.short_description}
          </TruncatedDescription>
        </CardContent>
        {isSelected && (
          <CheckCircle
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: theme.palette.primary.main,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              borderRadius: "50%",
            }}
          />
        )}
      </Card>
    </Grow>
  );
});

// Main AddressDrawer Component
const AddressDrawer = ({
  setForm,
  isSelectedSlote,
  MyFun,
  setCart,
  setBooking,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Redux Selectors
  const cart = useSelector((state) => state.cart);
  const baseCart = useSelector((state) => state.cart)?.base_cart;
  const orderDetails = useSelector(
    (state) => state.OrderCartDetails
  )?.orderDetails;
  const selectedDate = orderDetails && orderDetails.date;
  const isselectedTime = orderDetails && orderDetails.slot;
  const delivery_type = useSelector(
    (state) => state.DeliveryAddress
  )?.deliveryType;
  const deliveryAddress = useSelector(
    (state) => state.DeliveryAddress
  )?.delivery;
  const users_address = useSelector(
    (state) => state.UserAddress
  )?.address;
  const provincesList = useSelector(
    (state) => state.HinhcoLocation
  )?.provincesList;
  const famousPlaceList = useSelector(
    (state) => state.HinhcoLocation
  )?.famousPlaceData?.famous_places;

  const providerScope = cart?.provider_info?.scope_of_operation || "[]";
  const providerProvinces = useMemo(
    () => JSON.parse(providerScope).map((item) => item.code),
    [providerScope]
  );

  // Local States
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [filteredFamousPlaces, setFilteredFamousPlaces] = useState([]);
  const [selectedFamousPlace, setSelectedFamousPlace] = useState(null);
  const [openOtherLocation, setOpenOtherLocation] = useState(false);
  const [otherLocation, setOtherLocation] = useState("");
  const [note, setNote] = useState(orderDetails?.orderNote || "");
  const [visibleFamousPlaces, setVisibleFamousPlaces] = useState(4); // Initial number of famous places to show

  useEffect(() => {
    if (cart.at_store === "1" && cart.at_door === "1") {
      // Allow user to choose
    } else if (cart.at_store === "0" && cart.at_door === "1") {
      dispatch(setDeliveryAddressType("Home"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.at_store, cart.at_door, dispatch]);

  // Function to check slot availability
  const checkSlot = useCallback(async () => {
    const partner_id = baseCart && baseCart.provider_id;
    try {
      const response = await api.checkSlots({
        partner_id: partner_id,
        date: selectedDate,
        time: isselectedTime,
      });
      if (response.error === false) {
        if (deliveryAddress !== "" && delivery_type === "Home") {
          providerAvailable(-1, -1);
        } else {
          handleClose(setForm);
          handleOpen(setBooking);
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(t("Đã xảy ra lỗi. Vui lòng thử lại."));
    }
  }, [
    baseCart,
    selectedDate,
    isselectedTime,
    deliveryAddress,
    delivery_type,
    setForm,
    setBooking,
  ]);

  // Function to check provider availability
  const providerAvailable = useCallback(async (lat, lng) => {
    try {
      const result = await api.providerAvailable({
        latitude: lat,
        longitude: lng,
        isCheckout: 1,
      });
      if (result.error === false) {
        toast.success(result.message);
        handleClose(setForm);
        handleOpen(setBooking);
      } else {
        toast.error(t("Dịch vụ không khả dụng."));
      }
    } catch (error) {
      console.log(error);
      toast.error(t("Đã xảy ra lỗi. Vui lòng thử lại."));
    }
  }, [setForm, setBooking]);

  // Function to handle Continue button click
  const ContinueClicked = useCallback(() => {
    if (cart.at_store === "1" && cart.at_door === "1") {
      if (cart?.selectedView === null) {
        toast.error(t("Vui lòng chọn phương thức chụp!"));
        return;
      }
    }

    if (!selectedDate || !isselectedTime) {
      toast.error(t("Vui lòng chọn ngày và giờ chụp trước khi tiếp tục."));
      return;
    }

    if (delivery_type && delivery_type === "") {
      toast.error(t("Vui lòng chọn hình thức chụp trước khi tiếp tục."));
      return;
    }

    // If selecting in-store
    if (cart.at_store === "1" && cart.at_door === "0") {
      dispatch(setDeliveryAddressType("shop"));
    }

    // If selecting other location
    if (delivery_type === "Other" && otherLocation.trim() === "") {
      toast.error(t("Vui lòng nhập địa điểm chụp."));
      return;
    }

    checkSlot();
  }, [
    cart.at_store,
    cart.at_door,
    cart.selectedView,
    selectedDate,
    isselectedTime,
    delivery_type,
    otherLocation,
    dispatch,
    checkSlot,
  ]);

  // Function to handle opening next step
  const OpenNext = useCallback(() => {
    handleClose(setForm);
    handleOpen(isSelectedSlote);
  }, [setForm, isSelectedSlote]);

  // Function to handle going back
  const back = useCallback(() => {
    handleClose(setForm);
    handleOpen(setCart);
  }, [setForm, setCart]);

  // Function to handle selecting a delivery method
  const handleSelectMethod = useCallback(
    (method) => {
      dispatch(setDeliveryAddressType(method));
      if (method === "Home") {
        // Additional handling if needed
      }
    },
    [dispatch]
  );

  // Function to handle order note changes
  const handleOrderNote = useCallback(
    (value) => {
      setNote(value);
      dispatch(cartDetails({ orderNote: value }));
    },
    [dispatch]
  );

  // Function to handle selecting a province
  const handleSelectProvince = useCallback(
    (province) => {
      setSelectedProvince(province);
      // Filter famous places based on province
      const filtered = famousPlaceList.filter(
        (place) => place.province_code === province.code
      );
      setFilteredFamousPlaces(filtered);
      // Set delivery_type to "Famous" to enforce selection or input
      dispatch(setDeliveryAddressType("Famous"));
    },
    [famousPlaceList, dispatch]
  );

  // Function to handle selecting a famous place
  const handleSelectFamousPlace = useCallback(
    (place) => {
      if (selectedFamousPlace && selectedFamousPlace.id === place.id) {
        // Deselect if already selected
        setSelectedFamousPlace(null);
        dispatch(cartDetails({ selectedLocation: "" }));
        setOtherLocation("");
      } else {
        // Select new place
        setSelectedFamousPlace(place);
        dispatch(cartDetails({ selectedLocation: place.name }));
        // Set otherLocation to place name to display in TextField
        setOtherLocation(place.name);
      }
    },
    [selectedFamousPlace, dispatch]
  );

  // Function to open other location dialog
  const handleOpenOtherLocation = useCallback(() => {
    setOpenOtherLocation(true);
  }, []);

  // Function to close other location dialog
  const handleCloseOtherLocation = useCallback(() => {
    setOpenOtherLocation(false);
    setOtherLocation("");
  }, []);

  // Function to save other location
  const handleSaveOtherLocation = useCallback(() => {
    if (otherLocation.trim() === "") {
      toast.error(t("Vui lòng nhập địa điểm chụp."));
      return;
    }
    // Update selected location in Redux
    dispatch(cartDetails({ selectedLocation: otherLocation }));
    setOpenOtherLocation(false);
    // Deselect famous place if any
    setSelectedFamousPlace(null);
  }, [otherLocation, dispatch]);

  // Memoize available provinces to optimize performance
  const availableProvinces = useMemo(() => {
    return provincesList.filter((province) =>
      providerProvinces.includes(province.code)
    );
  }, [provincesList, providerProvinces]);

  // Memoize displayed famous places based on visibility
  const displayedFamousPlaces = useMemo(() => {
    return filteredFamousPlaces.slice(0, visibleFamousPlaces);
  }, [filteredFamousPlaces, visibleFamousPlaces]);

  // Function to handle showing more famous places
  const handleShowMoreFamousPlaces = useCallback(() => {
    setVisibleFamousPlaces((prev) => Math.min(prev + 4, 10)); // Increment by 4 up to 10
  }, []);

  // Function to clear selected famous place and enable input
  const handleClearSelectedPlace = useCallback(() => {
    setSelectedFamousPlace(null);
    dispatch(cartDetails({ selectedLocation: "" }));
    setOtherLocation("");
  }, [dispatch]);

  return (
    <Box
      className="overflow-hidden"
      sx={{
        backgroundColor: theme.palette.background.paper,
        minHeight: "100vh",
        paddingBottom: "80px", // Ensure space for fixed elements if any
      }}
    >
      {/* Header */}
      <Box mt={2} mb={1} display="flex" alignItems="center" px={2}>
        <IconButton onClick={back} size="large">
          <ArrowBackIosNewOutlined
            sx={{ color: theme.palette.text.primary }}
            fontSize="inherit"
          />
        </IconButton>
        <HeaderTitle ml={1}>
          {t("Chọn địa điểm và thời gian chụp")}{" "}
          {baseCart && ` nhé ${baseCart.company_name}`}
        </HeaderTitle>
      </Box>
      <Divider />

      {/* Select Date and Time */}
      <Box display="block" px={2} py={3}>
        <SectionTitle>{t("Chọn ngày và giờ chụp")}</SectionTitle>
        <DateTimeSelector
          onOpen={OpenNext}
          selectedDate={selectedDate}
          selectedTime={isselectedTime}
        />
      </Box>

      {/* Select Location */}
      <Box px={2} mt={2}>
        <SectionTitle>{t("Bạn muốn chụp ở đâu?")}</SectionTitle>
        {/* Provinces Grid */}
        <Grid container spacing={2}>
          {availableProvinces.map((province) => (
            <Grid item key={province.code} xs="auto">
              <ProvinceCard
                province={province}
                isSelected={selectedProvince?.code === province.code}
                onSelect={handleSelectProvince}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* If province selected, show location input and famous places */}
      {selectedProvince && (
        <Box px={2} mt={4}>
          {/* Location Input */}
          <TextField
            fullWidth
            label={t(
              `Nhập địa chỉ bạn muốn chụp tại ${selectedProvince.name_with_type}`
            )}
            variant="outlined"
            value={otherLocation}
            onChange={(e) => {
              setOtherLocation(e.target.value);
              if (selectedFamousPlace) {
                setSelectedFamousPlace(null);
                dispatch(cartDetails({ selectedLocation: "" }));
              }
            }}
            placeholder={
              selectedFamousPlace
                ? t(
                    "Bạn đã chọn địa điểm nổi tiếng, nếu muốn thay đổi hay nhấp vào đây"
                  )
                : t("Nhập địa chỉ cụ thể...")
            }
            sx={{ marginBottom: "24px" }}
            InputProps={{
              readOnly: !!selectedFamousPlace,
              endAdornment: selectedFamousPlace && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear selection"
                    onClick={handleClearSelectedPlace}
                    edge="end"
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                backgroundColor: selectedFamousPlace
                  ? theme.palette.action.disabledBackground
                  : theme.palette.background.paper,
                cursor: selectedFamousPlace ? "pointer" : "text",
              },
            }}
            helperText={
              selectedFamousPlace &&
              t(
                "Bạn đã chọn địa điểm nổi tiếng, nếu muốn thay đổi hay nhấp vào đây"
              )
            }
            onClick={() => {
              if (selectedFamousPlace) {
                handleClearSelectedPlace();
              }
            }}
          />

          {/* Or Select Famous Place */}
          <SubSectionTitle>
            {t("Hoặc bạn có thể chọn các địa điểm nổi tiếng dưới đây")}
          </SubSectionTitle>
          <Grid container spacing={3}>
            {displayedFamousPlaces.map((place) => (
              <Grid item xs={12} key={place.id}>
                <FamousPlaceCard
                  place={place}
                  isSelected={selectedFamousPlace?.id === place.id}
                  onSelect={handleSelectFamousPlace}
                />
              </Grid>
            ))}
          </Grid>

          {/* Show More Button */}
          {filteredFamousPlaces.length > visibleFamousPlaces && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="text"
                onClick={handleShowMoreFamousPlaces}
                disabled={visibleFamousPlaces >= 10}
              >
                {t("Xem thêm")}
              </Button>
            </Box>
          )}
        </Box>
      )}

      {/* Order Note */}
      <Box px={2} mt={4}>
        <SectionTitle>
          {t("Ghi chú cho nhiếp ảnh gia (tùy chọn)")}
        </SectionTitle>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder={t("Nhập yêu cầu hoặc ghi chú thêm...")}
          value={note}
          onChange={(e) => handleOrderNote(e.target.value)}
          sx={{
            backgroundColor: theme.palette.background.default,
            borderRadius: "8px",
          }}
        />
      </Box>

      {/* Continue Button */}
      <Box px={2} pt={4} mb={4}>
        <PrimaryButton fullWidth onClick={ContinueClicked}>
          {t("Đặt lịch ngay")}
        </PrimaryButton>
      </Box>

      {/* Dialog for Other Location */}
      <Dialog
        open={openOtherLocation}
        onClose={handleCloseOtherLocation}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{t("Nhập địa điểm chụp khác")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t("Địa điểm")}
            type="text"
            fullWidth
            variant="outlined"
            value={otherLocation}
            onChange={(e) => setOtherLocation(e.target.value)}
            sx={{ marginTop: "8px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOtherLocation} color="secondary">
            {t("Hủy")}
          </Button>
          <Button
            onClick={handleSaveOtherLocation}
            variant="contained"
            color="primary"
          >
            {t("Lưu")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default memo(AddressDrawer);
