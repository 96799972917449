// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const hinhcoLocationSlice = createSlice({
  name: "hinhcoLocation",
  initialState: {
    provincesList: [],
    famousPlaceData: []
  },
  reducers: {
    setProvincesList: (state, action) => {
      state.provincesList = action.payload;
    },
    setFamousPlaceData: (state, action) => {
      state.famousPlaceData = action.payload;
    },
  },
});

export const { setProvincesList,  setFamousPlaceData} = hinhcoLocationSlice.actions;

export default hinhcoLocationSlice.reducer;
