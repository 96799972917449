import "@stripe/stripe-js";
import { Paper, ThemeProvider, CircularProgress, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { darkTheme, lightTheme } from "./Theme";
import Routes from "./Routes";
import "swiper/css";
import "swiper/css/pagination";
import api from "./API/apiCollection";
import { setSettings } from "./redux/Settings";
import { setFamousPlaceData, setProvincesList } from "./redux/HinhcoLocation";
import { useDispatch, useSelector } from "react-redux";
import { changeMode, isDarkMode } from "./redux/Theme";
import { RootState } from "./typescriptTypes/globalTypes";

// main app component
function App(): JSX.Element {
  

  const dispatch = useDispatch();
  const locationData = useSelector((state: RootState) => state.Location);

  const [isLoading, setIsLoading] = useState(true); // Initial state is loading
  const darkMode = useSelector(isDarkMode);

  useEffect(() => {
    if (darkMode) {
      dispatch(changeMode(true));
      document.body.classList.add("dark-mode");
    } else {
      dispatch(changeMode(false));
      document.body.classList.remove("dark-mode");
    }
  }, [dispatch, darkMode]);

  useEffect(() => {
    if (!locationData.lat && !locationData.lng) {
      setIsLoading(false);
    }
  }, [locationData]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setIsLoading(true);
        const response = await api.get_settings();
        dispatch(setSettings(response.data));
      } catch (error) {
        console.error("Error fetching settings:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        setIsLoading(true);
        const response = await api.get_provinces();
        dispatch(setProvincesList(response.data));
      } catch (error) {
        console.error("Error fetching settings:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProvinces();
  }, []);



  useEffect(() => {
    const fetchFamousPlaces = async () => {
      try {
        setIsLoading(true);
        const response = await api.get_famous_places();
        dispatch(setFamousPlaceData(response.data));
      } catch (error) {
        console.error("Error fetching settings:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFamousPlaces();
  }, []);

  // console.log("DATA", {
  //   "get_provinces": state.provincesList,
  //   "get_famous_places": state.famousPlaceList,
  // })

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Paper>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Routes />
        )}
      </Paper>
    </ThemeProvider>
  );
}

export default App;
